export enum SectionId {
  ForInsuranceService = 'for-insurance-service',
  ForClinic = 'for-clinic',
  Partners = 'partners',
  Widgets = 'widgets',
  MisList = 'mis-list',
  Clinics = 'clinics'
}

export enum ContactFormRequest {
  ConnectMedflex = 'request_connect_medflex',
  ConnectInsurance = 'insurance',
  ConnectService = 'service',
  ConnectClinic = 'clinic',
  InstallingWidgets = 'request_installing_widgets'
}

export enum ContactFormDialogMessageType {
  Success = 'success',
  Error = 'error'
}
